/** * 名词解释 * @ prop data { * isShow: 弹窗展示, * key: 当前名词解释的key * }
*/
<template>
  <div>
    <van-action-sheet
      v-model="show"
      title="筛选"
      :style="{ height: '62%', maxHeight: 'unset' }"
    >
      <div class="content">
        <div class="item">
          <div class="item-title">类型</div>
          <div class="item-content">
            <div
              v-for="(item, idx) in typeArr"
              class="type-item"
              :class="{ 'item-active': item.value === filterData.type }"
              :key="'type_' + idx"
              @click="typeClick(item.value)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="item-title">标签</div>
          <div class="item-content" :style="{marginBottom:'16px'}">
            <div
              v-for="(item, idx) in labelArr"
              class="type-item label-item"
              :class="{ 'item-active': item.name === filterData.label }"
              :key="'label_' + idx"
              @click="labelClick(item.name)"
            >
              {{ item.name }}
            </div>
          </div>
        </div> -->
        <div class="item">
          <div class="item-title">人员</div>
          <div class="item-content">
            <div class="input-box" @click="peopleShowFlag = true">
              <div v-if="filterData.user && filterData.user.id != -1">
                {{ filterData.user.userName }}
                <div class="cross-panel" @click.stop="clearPersonClick">
                  <van-icon name="cross" />
                </div>
              </div>
              <div v-else class="placehold">
                <van-icon color="rgb(191, 192, 203)" size="14" name="search" />
                <span>点击搜索人员</span>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="reset" @click="onResetClick">重置</div>
          <div class="confirm" @click="onConfirmClick">查询</div>
        </div>
      </div>
    </van-action-sheet>
    <PersonSelectPanel
      :peopleShowFlag="peopleShowFlag"
      @handlePersonSelect="handlePersonSelect"
      @handlePersonCancel="handlePersonCancel"
    />
  </div>
</template>

<script>
import PersonSelectPanel from "./personSelectPanel.vue";

export default {
  props: ["isShow", "filter"],
  components: { PersonSelectPanel },
  data() {
    return {
      typeArr: [
        { name: "行驶", value: 1 },
        { name: "监控", value: 2 },
      ],
      labelArr: [
        { name: "全部标签", value: 1 },
        { name: "北区运营", value: 2 },
        { name: "北区配送", value: 3 },
        { name: "校园快递", value: 4 },
        { name: "校园外卖", value: 5 },
        { name: "南区运营", value: 6 },
        { name: "南区配送", value: 7 },
        { name: "超级货运", value: 8 },
        { name: "市场景区", value: 9 },
        { name: "测试", value: 10 },
        { name: "无", value: 11 },
      ],
      filterData: {},
      peopleShowFlag: false,
    };
  },
  computed: {
    show: {
      get: function () {
        return this.isShow || false;
      },
      set: function (val) {
        this.$emit("update:isShow");
      },
    },
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.filterData = JSON.parse(JSON.stringify(this.filter));
        }
      },
      immediate: false,
    },
  },
  created() { },

  mounted() { },

  methods: {
    typeClick(val) {
      this.filterData.type = val;
    },
    labelClick(val) {
      this.filterData.label = val;
    },
    handlePersonSelect(value) {
      this.filterData.user = value;
      this.handlePersonCancel();
    },
    handlePersonCancel() {
      this.peopleShowFlag = false;
    },
    clearPersonClick() {
      this.filterData.user = {
        id: -1
      };
    },
    onResetClick() {
      this.filterData = {
        type: 1,
        label: '全部标签',
        user: { id: -1 },
        status: -1
      };
      this.$emit('onConfirm', this.filterData)
    },
    onConfirmClick() {
      this.$emit('onConfirm', this.filterData)
    }
  },
};
</script>

<style scoped lang="scss">
.van-popup {
  background: rgb(246, 247, 248);
}
.content {
  position: relative;
  padding: 14px 16px;
  height: 100%;
  .item {
    .item-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    .item-content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      .type-item {
        width: 82px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 26px;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 400;
        margin-right: 8px;
        transition: all 0.4s;
      }
      .label-item {
        width: calc((100% - 16px) / 3);
        margin-bottom: 8px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      .item-active {
        background: rgb(67, 110, 255);
        color: white;
      }
      .input-box {
        position: relative;
        width: 100%;
        height: 36px;
        border-radius: 6px;
        background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        padding-left: 11px;
        font-size: 14px;
        font-weight: 400;
        .cross-panel {
          position: absolute;
          top: 0;
          right: 0;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .placehold {
          color: rgba(191, 192, 203, 1);
          span {
            margin-left: 8px;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 24px;
    height: 46px;
    display: flex;
    justify-content: space-between;
    .reset {
      width: 28.27vw;
      height: 46px;
      border-radius: 23px;
      border: 2px solid rgba(67, 110, 255, 1);
      font-size: 16px;
      font-weight: 600;
      color: rgba(67, 110, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .confirm {
      width: 58.67vw;
      height: 46px;
      border-radius: 23px;
      background: rgb(67, 110, 255);
      font-size: 16px;
      font-weight: 600;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
