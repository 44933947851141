<template>
  <div class="person-item">
    <div class="person-item-title">
      <span class="person-item-title-label">驾驶员 {{ item.userName }} </span>
    </div>
    <div class="person-item-content">
      <template v-if="type === 1">
        <div class="item-task">
          <span class="item-task-title">接管时长</span>
          <span class="item-task-value"> {{ `${item.duration} ${item.unit}` }}</span>
        </div>
        <div class="item-task">
          <span class="item-task-title">任务数</span>
          <span class="item-task-value">{{ item.missionNum }}</span>
        </div>
        <div class="item-task">
          <span class="item-task-title">超时未领</span>
          <span class="item-task-value">{{ item.timeoutMissionNum }}</span>
        </div>
      </template>
      <template v-else>
        <div class="item-task">
          <span class="item-task-title">监控时长</span>
          <span class="item-task-value"> {{ `${item.duration} ${item.unit}` }}</span>
        </div>
        <div class="item-task">
          <span class="item-task-title">当前监控数量</span>
          <span class="item-task-value">{{ item.missionNum }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonItem",
  props: ["item", "type"],
  data() {
    return {};
  },

  watch: {},

  created: function () {},

  mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.person-item {
  padding: 0;
  background: #ffffff;
  &-title {
    position: relative;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    span {
      position: relative;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
    }
    img {
      width: 42px;
      height: 22px;
    }
  }
  &-content {
    padding-top: 12px;
    padding-bottom: 4px;
    .item-task {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      &-title {
        display: inline-block;
        min-width: 56px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #9798ab;
        font-weight: 400;
      }
      &-value {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        text-align: right;
        font-weight: 400;
      }
    }
  }
}
</style>
