<template>
  <div class="driving-manage" ref="scrollDiv">
    <div class="fix-box">
      <div id="headerBox" ref="headerRef" class="header">
        <div
          :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"
        ></div>
        <div class="navBar">
          <img
            @click="handleBack"
            src="../../assets/app/common/navi-back@2x.png"
            alt=""
            :style="{ left: '14px' }"
          />
          <div class="tab-list">
            <span
              v-for="(tab, idx) in tabArr"
              class="tab"
              :key="`tab_${idx}`"
              :class="active === tab.value ? 'active' : ''"
              @click="() => changeTab(tab.value)"
              >{{ tab.name }}
            </span>
          </div>
          <div class="filter-box" @click="isShowFilter = true">
            <img :src="filterImg" />
          </div>
        </div>
      </div>
      <card-filter
        :cardArr="cardArr"
        :active="cardActive"
        @onHandleCard="onHandleCard"
      ></card-filter>
    </div>
    <div class="fix-box-placehold" :style="{ height: `${headHeight}px` }"></div>
    <van-list class="list-content">
      <van-cell v-for="(item, idx) in list" :key="'cell_' + idx" :border='false'>
        <task-item
          v-if="active === 1"
          :item="item"
          :type="filter.type"
        ></task-item>
        <person-item
          v-if="active === 2"
          :item="item"
          :type="filter.type"
        ></person-item>
      </van-cell>
    </van-list>
    <van-empty
      class="empty"
      v-if="!isLoading && list.length === 0"
      description="暂无数据"
      :image="require('../../assets/app/dataCenter/empty@2x.png')"
    />
    <search-filter
      :isShow.sync="isShowFilter"
      :filter="filter"
      @onConfirm="onConfirm"
    ></search-filter>
  </div>
</template>

<script>
import {} from "@/api/api";
import request from "@/utils/request";
import SearchFilter from "./components/SearchFilter.vue";
import CardFilter from "./components/CardFilter.vue";
import filterCommon from "../../assets/app/IntelligentDrivingManage/filter-common@2x.png";
import filterSelected from "../../assets/app/IntelligentDrivingManage/filter-selected@2x.png";
import TaskItem from "./components/TaskItem.vue";
import PersonItem from "./components/PersonItem.vue";
export default {
  components: { CardFilter, SearchFilter, TaskItem, PersonItem },
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem("_appBarHeight") ?? 0),
      headHeight: 48 + 96,
      tabArr: [
        { name: "任务", value: 1 },
        { name: "人员", value: 2 },
      ],
      active: 1,
      cardArr: [],
      isShowFilter: false,
      filter: {
        type: 1,
        label: "全部标签",
        user: { id: -1 },
        status:-1,
      },
      cardActive: -1,
      missionStatus: -1,
      driverStatus: -1,
      isLoading: true,
      list: [],
      timer: null,
    };
  },
  computed: {
    filterImg: function () {
      return this.filter.label !== "全部标签" || this.filter.user.id !== -1
        ? filterSelected
        : filterCommon;
    },
    filterOptions: function(){
      return {
        filter: this.filter,
        active:this.active
      }
    },
    cachesFilter:function(){
      return {
        type: this.filter.type,
        label: this.filter.label,
        user:this.filter.user
      }
    },
  },
  watch: {
    'filter.type':{
      handler() {
        const val = -1
        this.filter.status = val;
        this.missionStatus = val;
        this.driverStatus = val;
      },
      immediate: false,
    },
    filterOptions: {
      handler() { 
       this.queryDataStart()
      },
      immediate: false,
      deep: true,
    },
    cachesFilter: {
      handler() { 
        this.utils.setLocalStorageDataByApp(JSON.stringify(this.cachesFilter));
      },
      immediate: false,
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.headHeight += this.appBarHeight;
    window.getIntelligentDrivingManageFilter = this.getStoreDataByApp  
    if (process.env.NODE_ENV === "development"){
      this.getStoreDataByApp()     // 准时达App会有原生回调,所以脱离准时达App是调试需要打开此方法
    }
  },
  destroyed() {
    this.clearTimer()
  },

  methods: {
    handleBack() {
      window.location.href = "neolix://more";
    },
    changeTab(value) {
      if (this.active === value) return;
      this.active = value;
      this.filter.status = value === 1?this.missionStatus:this.driverStatus
      
    },
    onConfirm(data) {
      this.filter = data;
      this.isShowFilter = false;
    },
    onHandleCard(val) {
      if (this.active === 1) {
        this.missionStatus = val;
      } else {
        this.driverStatus = val;
      }
      this.filter.status = val
      this.cardActive = this.filter.status
    },
    queryDataStart(){
      this.queryData(false);
      this.clearTimer()
      this.timer = setInterval(() => {
        this.queryData(true)
      }, 5000);
    },
    queryData(isTimer) {
      if(!isTimer){
        this.$loadingCircle.start();
        this.isLoading = true;
        this.list =[]
      }
      if (this.active === 1) {
        this.queryMissionServe(isTimer);
      } else {
        this.queryDriverServe(isTimer);
      }
    },
    // 智能任务查询接口
    queryMissionServe(isTimer) {
      request({
        url: "/dashboard-center/vehicleMissionStatistic/queryMission",
        method: "POST",
        data: {
          isMonitor: this.filter.type === 1?false:true,
          label: this.filter.label,
          userId: this.filter.user.id === -1 ? undefined : this.filter.user.id,
          missionStatus:
          this.filter.status === -1 ? undefined : this.filter.status,
          pageNo: 0,
          pageSize: 10000,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          const {list,listTitle} = res
          this.list = list;
          this.cardArr = listTitle
          this.cardActive = this.filter.status
        })
        .finally(() => {
          this.$loadingCircle.end();
          this.isLoading = false;
        });
    },
    // 人员管理查询接口
    queryDriverServe(isTimer) {
      request({
        url: "/dashboard-center/vehicleMissionStatistic/queryDriver",
        method: "POST",
        data: {
          isMonitor: this.filter.type === 1?false:true,
          label: this.filter.label,
          userId: this.filter.user.id === -1 ? undefined : this.filter.user.id,
          driverStatus:
          this.filter.status === -1 ? undefined : this.filter.status,
          pageNo: 0,
          pageSize: 10000,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          const {list,listTitle} = res
          this.list = list;
          this.cardArr = listTitle
          this.cardActive = this.filter.status
        })
        .finally(() => {
          this.$loadingCircle.end();
          this.isLoading = false;
        });
    },
    clearTimer(){
    if( this.timer){
        clearTimeout(this.timer);
        this.timer = null
      }
    },
    // 获取缓存的筛选条件
    getStoreDataByApp(data){
      if(data){
        this.filter = {
          ...this.filter,
          ...data,
        }
      } else {
        this.queryDataStart()
      }
    }
  },
};

</script>

<style scoped lang="scss">
.driving-manage {
  position: relative;
  background: #f6f7f8;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .fix-box {
    width: 100vw;
    position: fixed;
    z-index: 3;
    background: #f6f7f8;
    top: 0;
    .header {
      .navBar {
        height: 48px;
        line-height: 48px;
        position: relative;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        font-weight: bold;
        .tab-list {
          background: white;
        }
        img {
          position: absolute;
          width: 24px;
          height: 24px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 3;
        }
      }
      .tab {
        width: 2rem;
        text-align: center;
        display: inline-block;
        color: #7a7b92;
        position: relative;
      }
      .active {
        color: #436eff !important;
        position: relative;
        &::after {
          content: "";
          width: 60px;
          height: 3px;
          border-radius: 2px;
          background: #436eff;
          position: absolute;
          bottom: 6px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .filter-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 52px;
        display: flex;
        justify-content: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .fix-box-header-placehold {
    height: 134px;
  }
  .filter-fixed {
    position: fixed;
    height: 98px;
    top: 48px;
    left: 0;
    right: 0;
    background: white;
    z-index: 3;
  }
  .filter-fixed-placehold {
    width: 100%;
    height: 98px;
  }
  .list-content {
    padding: 0 14px;
    .van-cell {
      border-radius: 8px;
      margin-bottom: 15px;
      padding: 0 18px;
    }
  }
  .empty {
    margin-top: 70px;
    ::v-deep .van-empty__description {
      color: #000;
    }
  }
}
</style>
