/**
卡片筛选
cardArr:数组
active:当前激活的value
*/
<template>
  <div class="card-fliter">
    <div class="card-list">
      <div
        v-for="(item, idx) in cardArr"
        :key="`card_${idx}`"
        @click="clickType(item.status)"
        class="card-content"
        :class="{'card-content-active':(active === -1 && idx == 0) || active === item.status }"
      >
        <p class="card-status">{{ item.name }}</p>
        <p class="card-status-num">{{ item.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardFilter",
  props:['cardArr','active'],
  data() {
    return {
     
    };
  },

  watch: {},

  created: function () {},

  mounted() {},

  methods: {
    clickType(value) {
      this.$emit("onHandleCard", value);
    },
  },
};
</script>

<style scoped lang="scss">
.card-fliter {
  padding: 14px;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .card-list {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    .card-content {
      width: 80px;
      height: 64px;
      color: rgba(151, 152, 171, 1);
      background: #ffffff;
      border-radius: 4px;
      margin-left: 9px;
      padding-top: 8px;
      border: 2px solid white; 
      .card-status {
        width: 78px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        margin: 0 auto;
      }
      .card-status-num {
        height: 32px;
        line-height: 32px;
        font-size: 22px;
        text-align: center;
        font-weight: 600;
        margin-top: -3px;
      }
    }
    .card-content:first-child{
      margin-left: 0;
    }
    .card-content-active{
      background: rgba(255, 255, 255, 1);
      border: 2px solid rgba(66, 110, 255, 1); 
      color: rgba(0, 0, 0, 1);   
    }
  }
}
</style>
