<template>
  <div class="task-item">
    <div class="task-item-title">
      <span>
        <span class="task-item-title-label">车号 </span>
        <span v-if="item.vinCode">{{ item.vinCode }} ({{ item.vinId }})</span>
        <span v-else>{{ item.vinId }}</span></span
      >
    </div>
    <div class="task-item-content">
      <div class="item-task">
        <span class="item-task-title">驾驶员</span>
        <span class="item-task-value">{{ item.userName }}</span>
      </div>
      <template v-if="type === 1">
        <div class="item-task">
          <span class="item-task-title">开始时间</span>
          <span class="item-task-value">{{ item.executeTime }}</span>
        </div>
        <div class="item-task">
          <span class="item-task-title">目的地</span>
          <span class="item-task-value">{{ item.destinationStationName }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskItem",
  props: ["item",'type'],
  data() {
    return {};
  },

  watch: {},

  created: function () {},

  mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.task-item {
  padding: 0;
  background: #ffffff;
  &-title {
    position: relative;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    span {
      position: relative;
      font-family: PingFangSC-Semibold;
      font-size: 16px;
      color: #000000;
      font-weight: 600;
    }
    img {
      width: 42px;
      height: 22px;
    }
  }
  &-content {
    padding-top: 12px;
    padding-bottom: 9px;
    .item-task {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      &-title {
        display: inline-block;
        min-width: 53px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #9798ab;
        font-weight: 400;
      }
      &-value {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        text-align: right;
        font-weight: 400;
      }
    }
  }
  .item-driver-content {
    padding: 12px 0;
    display: flex;
    .item-driver {
      width: 33.33%;
      text-align: center;
      &-value {
        font-family: DINPro-Medium;
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        .unit {
          font-size: 14px;
        }
      }
      &-lable {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #7a7b92;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
  }
}
</style>
